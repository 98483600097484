import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import YoutubePlayerLite from "../../components/common/youtube-player-lite"
import { graphql, useStaticQuery } from "gatsby"
import PageSelection from "../../components/common/PageSelection"
import GridItems from "../../components/common/GridItems"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useWindowSize from "../../utils/useWindowSize"
import FunGridItems from "../../components/common/FunGridItems"
import styled from "styled-components"
import Tabs from "../../components/common/Tabs"

const FunPage = () => {
  const screen = useWindowSize()

  const prismicFun = useStaticQuery(graphql`
    query FundPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "fun" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
      allPrismicFunAtBja {
        nodes {
          uid
          data {
            title
            date
            preview_image {
              alt
              fluid(maxWidth: 600) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const origFunList = useMemo(() => {
    if (prismicFun) {
      const data = prismicFun.allPrismicFunAtBja.nodes.map(node => {
        const { uid, data, tags } = node
        const { title, date, preview_image } = data

        return {
          uid,
          title,
          date: new Date(date),
          image: preview_image.fluid.src,
          image_alt: preview_image.alt ?? title,
          tags,
        }
      })

      data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })

      return data
    }

    return []
  }, [prismicFun])

  const image = getImage(
    prismicFun.allPrismicBasePage?.edges[0].node.data.hero_image
  )

  return (
    <Layout>
      <SEOnew title={prismicFun.allPrismicBasePage?.edges[0].node.data.title} />
      <div className="w-full bg-site-gray flex overflow-hidden relative lg:rounded-bl-100 lg:h-500 flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 h-full relative flex">
          <GatsbyImage
            image={image}
            alt={prismicFun.allPrismicBasePage?.edges[0].node.data.title}
            style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-transparent bg-toTopGray lg:bg-toRightGray">
            &nbsp;
          </div>
        </div>
        <div className="w-full lg:w-1/2 pt-12 lg:pt-24 pb-12 px-4 flex flex-col ">
          <div className="p-1 text-site-yellow flex flex-col text-base lg:text-2xl gap-y-4 px-2 md:px-4 h-full justify-center">
            <p>
              "Architecture should be an honest reflection of the owner’s goals
              and expectations while respectfully embracing and balancing the
              demands and benefits of the site. Architecture should enhance the
              quality of life for the client and the environment" - Brion
              Jeannette
            </p>
            <p>
              "Our goal is to create unique architecture in a responsible manner
              – not only for our clients, but also for our environment" - Amy J.
              Creager
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-8 lg:pt-12 mb-12">
        <div className="w-full max-w-7xl px-4">
          <Tabs active={3} />
          <FunGridItems items={origFunList} />
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default FunPage
