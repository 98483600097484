import React, { useMemo } from "react"
import styled from "styled-components"

const TitleDiv = styled.div`
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const DateDiv = styled.div`
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.75);
`

const FunGridItems = ({ items = [] }) => {
  const sortedItems = useMemo(() => {
    return items.sort((a, b) => b.date - a.date)
  }, [items])
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-20 py-2 md:py-12 relative justify-center justify-items-center">
      {sortedItems.map((press, index) => {
        return (
          <div
            className="w-full max-w-xs h-96 relative flex rounded-md overflow-hidden portfolio-page-item"
            key={`press-${index}-${press.uid}`}
            style={{
              boxShadow: "0px 5px 12px 2px #333333",
              animationDelay: `${0.5 + index * 0.2}s`
            }}
          >
            <img
              src={press.image}
              alt={press.image_alt}
              className="w-full h-full object-cover"
            />
            <a
              href={`/fun-details/${press.uid}`}
              className="press-page-gridItem"
            >
              <TitleDiv className="text-center w-full px-2 pb-2 py-4">
                {press.title || <>&nbsp;</>}
              </TitleDiv>
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default FunGridItems
